










import { Component, Vue, Prop } from 'vue-property-decorator';
import { IvyPinch } from './ivypinch';
import { Properties } from './interfaces'; 
import { defaultProperties } from './properties';

@Component
export default class PinchZoom extends Vue {
    @Prop() properties: Properties;
    @Prop() transitionDuration: number;
    @Prop() doubleTap: boolean;
    @Prop() doubleTapScale: number;
    @Prop() autoZoomOut: boolean;
    @Prop() limitZoom: number | "original image size";
    @Prop() disabled: boolean;
    @Prop() disablePan: boolean;
    @Prop() overflow: "hidden" | "visible";
    //@Prop() zoomControlScale: number;
    @Prop() disableZoomControl: "disable" | "never" | "auto";
    @Prop() backgroundColor: string;
    @Prop() limitPan: boolean;
    @Prop() minScale: number;
    @Prop() listeners: 'auto' | 'mouse and touch';
    @Prop() wheel: boolean;
    @Prop() autoHeight: boolean;
    @Prop() wheelZoomFactor: number;
    @Prop() draggableImage: boolean;

    _properties: Properties;
    ivyPinch: any;
    styleObject:any;
    isZoomedIn: boolean = false;

    get isTouchScreen() {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function(query:any) {
            return window.matchMedia(query).matches;
        }

        if (('ontouchstart' in window)) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }

    created() {
        let changedOptions = this.getProperties(this.$props);
        this.applyOptionsDefault(defaultProperties, changedOptions);
        this.setStyles();
    }

    mounted() {
        this.init();
    }

    beforeDestroy() {
      this.ivyPinch.destroy();
    }

    isDragging() {
        if (!this.ivyPinch) {
            return undefined;
        }
        return  this.ivyPinch.isDragging();
    }

    isControl() {
        if (this._properties['disabled']) {
            return false;
        }

        if (!this._properties) { return undefined; }

        if (this._properties['disableZoomControl'] === "disable") {
            return false;
        }

        if (this.isTouchScreen && this._properties['disableZoomControl'] === "auto") {
            return false;
        }

        return true;
    }

    getScale() {
        if (!this.ivyPinch) { return undefined; }
        return this.ivyPinch.scale;
    }

    init() {
        if (this._properties['disabled']) {
            return;
        }

        this._properties['element'] = this.$refs.wrapper;
        this._properties['eventHandler'] = this.myEventHandler;
        this.ivyPinch = new IvyPinch(this._properties);

        this.pollLimitZoom();
    }

    getProperties(changes: any){
        let properties: any = {};

        for (var prop in changes) {
            if (changes[prop] !== undefined) {
                if (prop !== 'properties'){
                    properties[prop] = changes[prop];
                }
                if (prop === 'properties'){
                    properties = changes[prop];
                }
            }
        }
        return properties;
    }

    applyOptionsDefault(defaultOptions: any, options: any): void {
        this._properties = Object.assign({}, defaultOptions, options);
    }

    myEventHandler(event:any) {
        if (event.name === "wheel") {
            this.isZoomedIn = event.detail.scale > 1;
        }
    }

    toggleZoom() {
        this.ivyPinch.toggleZoom();
        this.isZoomedIn = this.getScale() > 1;
    }

    pollLimitZoom() {
        this.ivyPinch.pollLimitZoom();
    }

    setStyles() {
        this.styleObject = {
            'overflow': this._properties['overflow'],
            'background-color': this._properties['backgroundColor']
        };
    }
}
